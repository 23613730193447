<template>
  <div style="text-align: left">
    analysis
  </div>
</template>

<script>
import Notice from "@/components/baseNotice";
import {get,post,del} from "@/api/http.js";
export default {
  name: "routes",
  data () {
    return {
    }
  },
  methods: {
    timestampToTime (cjsj) {
      var date = new Date(cjsj) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-'
      var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-'
      var D = date.getDate() + ' '
      var h = date.getHours() + ':'
      var m = date.getMinutes() + ':'
      var s = date.getSeconds()
      return Y+M+D+h+m+s
    },
    time_str(timestamp){
      return (this.timestampToTime (timestamp))
    },
  }
}
</script>

<style lang="less" scoped>
.search-con {
  padding: 10px 0;

  .search {
    &-col {
      display: inline-block;
      width: 200px;
    }

    &-input {
      display: inline-block;
      width: 250px;
      margin-left: 2px;
    }

    &-btn {
      margin-left: 2px;
    }
  }
}
</style>
